import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Card from "../../components/blog/card/card"
import BlogNav from "../../components/blog/nav/nav"

export const data = graphql`
  query($slug: String!) {
    dibCategories(slug: { eq: $slug }) {
      title
      slug
    }
    allDibPosts(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          title
          summary
          slug
          publishedAt
          readtime
          featuredImage
          categories {
            title
            slug
          }
          author {
            name
            slug
            photo
          }
        }
      }
    }
  }
`

const TabsPage = props => {

  const posts = props?.data?.allDibPosts?.edges
  const category = props?.data?.dibCategories

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="blog-header">
            <h1>{category.title}</h1>
            <BlogNav />
          </div>
          <div className="row">
            {posts.map((post, index) => (
              <div key={index} className="col-sm-6 col-md-4 mb-2">
                <Card post={post.node} tab={category} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TabsPage
